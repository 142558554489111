import { baseService } from "./base.service";
export const userService = {
  login,
  verify2FA,
  getUsers,
  addUser,
  getUserById,
  updateUserById,

  getMyProfile,
  updateMyProfile,
  updatePassword,
};

function login(email, password) {
  return baseService.api
    .post("/v1/auth", { loginKey: email, password })
    .then(baseService.handleResponse)
    .then((response) => {
      if (response.user && response.twoFactor == false) {
        localStorage.setItem("user", JSON.stringify(response));
      }
      return response;
    })
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function verify2FA(code, secureCode) {
  return baseService.api
    .post("/v1/auth/otp", { code, secureCode })
    .then(baseService.handleResponse)
    .then((response) => {
      if (response.user) {
        localStorage.setItem("user", JSON.stringify(response));
      }
      return response;
    })
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getUsers(role) {
  const url = role ? "/v1/users?role=" + role : "/v1/users";
  return baseService.api
    .get(url)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function addUser(user) {
  return baseService.api
    .post("/v1/users", user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getUserById(id) {
  return baseService.api
    .get(`/v1/users/${id}`)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateUserById(id, user) {
  return baseService.api
    .patch(`/v1/users/${id}`, user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getMyProfile() {
  return baseService.api
    .get("/v1/users/my-profile")
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateMyProfile(user) {
  return baseService.api
    .put("/v1/users/my-profile", user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updatePassword(user) {
  return baseService.api
    .post("/v1/auth/change-password", user)
    .then(baseService.handleResponse)
    .catch(baseService.handleError)
    .catch((error) => {
      return Promise.reject(error);
    });
}
